body {
    min-width: 320px;
    max-height: 100vh;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 320px) {
    body {
        max-width: 100vw;
        overflow: hidden;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto !important;
}

.full-width {
    width: 100%;
}

.container-small {
    max-width: 1200px;
    margin: 0 auto !important;
}

.underline {
    position: relative;
    margin-bottom: 50px !important;
}

.underline::after {
    content: "";
    position: absolute;
    width: 80px;
    height: 2px;
    bottom: -12px;
    left: 0;
    background-color: #ccc;
}